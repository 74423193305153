import store from '@/state/store'

export default [
    {
        path: '/',
        name: 'default',
        meta: {authRequired: true,},
        component: () => import('./views/Dashboards'),
    },
    {
        path: '/product',
        name: 'product',
        meta: {authRequired: true},
        component: () => import('./views/Product/index.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/Account/login'),
        beforeEnter: (to, from, next) => {
            if (store.getters['authfack/loggedIn']) {
                next({name: 'default'})
            } else {
                next()
            }
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('./views/Account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({name: 'default'})
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot password',
        component: () => import('./views/Account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({name: 'default'})
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/reset-password',
        name: 'Reset password',
        component: () => import('./views/Account/reset-password.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters['auth/loggedIn']) {
                    next({name: 'default'})
                } else {
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./views/Account/logout'),
        beforeEnter: () => {
            store.dispatch('authfack/logout')
        },
        meta: {
            authRequired: true,
        },
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //   path: "*",
    //   redirect: "404",
    // },
    {
        path: '/plans',
        name: 'plans',
        meta: {authRequired: true},
        component: () => import('./views/Plans/AllPlanList.vue'),
    },
    {
        path: '/plan/:planId',
        name: 'plan/details',
        meta: {authRequired: true},
        component: () => import('./views/Plans/Details'),
    },
    {
        path: '/plan/builder',
        name: 'plan/builder',
        meta: {authRequired: true},
        component: () => import('./views/Plans/Builder'),
    },
    {
        path: '/plan/:planId/builder',
        name: 'plan/edit',
        meta: {authRequired: true},
        component: () => import('./views/Plans/Builder'),
    },
    {
        path: '/plan/preview',
        name: 'plan/preview',
        meta: {authRequired: true},
        component: () => import('./views/Plans/Preview'),
    },
    {
        path: '/addons',
        name: 'addons',
        meta: {authRequired: true},
        component: () => import('./views/AddOn/List.vue'),
    },
    {
        path: '/addon/add',
        name: 'addon/add',
        meta: {authRequired: true},
        component: () => import('./views/AddOn/CreateAddOn'),
    },
    {
        path: '/addon/add/:addonId/builder',
        name: 'addon/edit',
        meta: {authRequired: true},
        component: () => import('./views/AddOn/CreateAddOn'),
    },
    {
        path: '/addon/add/:addonId',
        name: 'addon/add/details',
        meta: {authRequired: true},
        component: () => import('./views/AddOn/Details.vue'),
    },
    {
        path: '/customers',
        name: 'customers',
        meta: {authRequired: true},
        component: () => import('./views/Customers/List.vue'),
    },
    {
        path: '/customer/:customerId',
        name: 'customer-detail',
        meta: {authRequired: true},
        component: () => import('./views/Customers/Details.vue'),
    },
    {
        path: '/transactions',
        name: 'transactions',
        meta: {authRequired: true},
        component: () => import('./views/Transactions/List.vue'),
    },
    {
        path: '/integrations',
        name: 'integrations',
        meta: {authRequired: true},
        component: () => import('./views/Integration/index.vue'),
    },
    {
        path: '/activate',
        name: 'activate',
        meta: {authRequired: true},
        component: () => import('./views/ActivateTerms/index.vue'),
    },
    {
        path: '/plan/exclusive',
        name: 'plan-exclusive',
        meta: {authRequired: true},
        component: () => import('./views/ExclusivePlan'),
    },
    {
        path: '/invoice/:invoiceId?',
        name: 'invoice-detail',
        meta: {authRequired: true},
        component: () => import('./views/Invoice/Details.vue'),
    },
    {
        path: '/workflows',
        name: 'workflows',
        meta: {authRequired: true},
        component: () => import('./views/WorkflowSetting'),
    },
    {
        path: '/users',
        name: 'users',
        meta: {authRequired: true},
        component: () => import('./views/Users/List.vue'),
    },
]
